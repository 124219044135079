import Enum from 'enum'

import HeroSort from './json/hero-sort.json'

const kana = HeroSort.filter(h => h.kana !== '')
const kanaEnum = () => {
  const _kana = {}
  kana.forEach(k => {
    _kana[k.hero_id] = k.kana
  })
  return new Enum(_kana)
}
const sortLabel = new Enum({
  'A': {
    letters: 'アイウエオあいうえお',
    label: 'ア',
  },
  'KA': {
    letters: 'カキクケコかきくけこガギグゲゴがぎぐげご',
    label: 'カ',
  },
  'SA': {
    letters: 'サシスセソさしすせそザジズゼゾざじずぜぞ',
    label: 'サ',
  },
  'TA': {
    letters: 'タチツテトたちつてとダヂヅデドだぢづでど',
    label: 'タ',
  },
  'NA': {
    letters: 'ナニヌネノなにぬねの',
    label: 'ナ',
  },
  'HA': {
    letters: 'ハヒフヘホはひふへほバビブベボばびぶべぼパピプペポぱぴぶぺぼ',
    label: 'ハ',
  },
  'MA': {
    letters: 'マミムメモまみむめも',
    label: 'マ',
  },
  'YA': {
    letters: 'ヤユヨやゆよ',
    label: 'ヤ',
  },
  'RA': {
    letters: 'ラリルレロらりるれろ',
    label: 'ラ',
  },
  'WA': {
    letters: 'ワンわんヴゔ',
    label: 'ワ',
  },
})

function findLabel(name) {
  const firstLetter = name.substring(0, 1)
  let res = sortLabel.enums.map(s => {
    const check = s.value.letters.split('')
    return check.some(c => c === firstLetter) ? s.value.label : null
  })
  res = res.filter(r => r)[0]
  return res ? res : null
}

function kanaToHira(str) {
  return str.replace(/[\u30a1-\u30f6]/g, function(match) {
    var chr = match.charCodeAt(0) - 0x60
    return String.fromCharCode(chr)
  })
}

export function getKeyByLabel(label) {
  const res = sortLabel.enums.find(s => s.value.label === label)
  return res ? res.key : label
}

export function getKana(data, locale) {
  if (locale === 'ja-jp') {
    let kana = kanaEnum()[data.hero_id]
    kana = kana ? kana.value : kanaToHira(data.name)
    return kana
  } 
  if (locale === 'zh-tw') {
    return null
  }
  return data.name
}

export function getSortLabel(data, locale) {
  if (locale === 'ja-jp') {
    return findLabel(data.name) || findLabel(getKana(data,locale))
  }
  if (locale === 'zh-tw') {
    const sortNum = HeroSort.filter(h => +h.hero_id === +data.hero_id)
    return +sortNum[0].zh_tw_sort
  }
  return data.name.substring(0, 1).toLowerCase()
}